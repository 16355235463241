import React, { useEffect } from 'react';
import '../App.css'
import logo from '../img/logo.jpg';

import { Link, Outlet } from 'react-router-dom';
import { useState } from 'react';
import {collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';


function WantToDo() {

    const [want, setWant] = useState("");

    useEffect(() => {
        // storing input name
        localStorage.setItem("want", JSON.stringify(want));
      }, []);

    useEffect(() => {
        // storing input name
        localStorage.setItem("want", JSON.stringify(want));
      }, [want]);

    function onChange(e) {
        setWant(e.target.value);
    }

    const onSubmit = async () => {
 
        try {
            const docRef = await addDoc(collection(db, "wants"), {
                wants: want,    
            });
            console.log("Document written with ID: ", docRef.id);
            } catch (e) {
            console.error("Error adding document: ", e);
            }
    }

    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        marginTop: "20px",
        fontSize: "1.25rem"
    }

    const formStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
    }

    const logoStyle = {
        height: "100px",
        width: "100px",
      }

    return (
        <div style={mainContainerStyle}>
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            <form style={formStyle}>
                <label className="IWantToLabel">
                    I want to

                </label>
                <input type="text" placeholder="scroll TikTok..." onChange={(e) => onChange(e)}></input>
            </form>
            <Link onClick={onSubmit} to="/trigger">Next</Link>
            <Outlet/>
        </div>

    );
}

export default WantToDo;