import React from 'react';
import logo from '../img/logo.jpg';
import {Link, Outlet} from 'react-router-dom';




function ErrorPage() {

    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "1.25rem"
    }

    const logoStyle = {
        height: "100px",
        width: "100px",
      }
    return(
        <div style={mainContainerStyle}>
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            <p>Oops, we can't find what you're looking for :( </p>
            <Link to='/'>Go Home</Link>
            <Outlet/>
        </div>
    );
}

export default ErrorPage;