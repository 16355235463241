import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import logo from '../img/logo.jpg';


function ReframedThought() {

      const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        marginTop: "20px",
        fontSize: "1.25rem"
    }

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    }

    const logoStyle = {
        height: "100px",
        width: "100px",
      }

    const linkStyle = {
        fontSize: "1.25rem",
        paddingTop: "10px",
    }

    return (
        <div style={mainContainerStyle}>
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            <form style={formStyle}>
                <label>Write your new reframed thought here:</label>
                <textarea rows="5" cols="20" placeholder="Maybe I won't feel better after scrolling..."></textarea>
            </form>
            <Link style={linkStyle} to='/congrats'>Next</Link>
        </div>
    );
}

export default ReframedThought;