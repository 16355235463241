import React from 'react';
import '../App.css';
import logo from '../img/logo.jpg';
import {Outlet, Link} from "react-router-dom";

function LandingPage() {

  // to do move link style here:

  const mainContainerStyle = {
    textAlign: "center",
    minHeight: "100vh",
    maxWidth: "65vw",
    margin: "30px 50px",
  }

  const landingHeaderStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem"
  }

  // TO DO LOOK AT THIS AND THE STUFF BEFORE WHERE IT IS USED. MAYBE SPREAD OPERATOR ISN'T BEING USED PROPERLY
    // Media query for smaller screens (e.g., mobile phones)
    const smallerScreenStyles = {
      landingHeader: {
        fontSize: "3rem",  /* Adjusted font size for smaller screens */
      },
    };

  const logoStyle = {
    height: "200px",
    width: "200px",
  }

  const boldedStyle = {
    fontWeight: "bold"

}



    return (
        <div style={mainContainerStyle}>
          <header style={{landingHeaderStyle, smallerScreenStyles}}> 
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>
      
              <h2>
                You want to spend less time on your phone
              </h2>
      
              <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>
            </header>
    
            <p>
              Have you tried everything to get your screen time under control, but nothing works?
              <br/><br/>Sick of all the app blockers and screen time controls?
              <br/><br/>This game guides you through a series of questions that help you understand why you spend so much time on the Internet. It is based on Cognitive Behavioral Therapy, a popular form of therapy used worldwide.
              <br/><br/><span style={boldedStyle}>Every time you want to use your phone, play this game instead!</span>
              <br/>
              <br/>
          </p>
          
          <Link className="linkStyle" to="/what-do-you-want-to-do">I'm ready to play</Link>

          <Outlet/>

  
      </div>
    );
}

export default LandingPage;