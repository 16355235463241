import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import logo from '../img/logo.jpg';


function AutomaticThoughts() {

    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        marginTop: "20px",
        fontSize: "1.25rem"
    }

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    }

    const formItemStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "5px"
    }


    const logoStyle = {
        height: "100px",
        width: "100px",
      }

    const linkStyle = {
        fontSize: "1.25rem",
        paddingTop: "10px",
    }


    return (
        <div style={mainContainerStyle}>
            
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            
            <p> What are your underlying thoughts and beliefs about this?
            <br/><br/>
            </p>

            <form style={formStyle}>
                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>It will just take five minutes!</label>
                </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>I might miss out on something important (FOMO)</label>
                </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>This will help me relax after a long day</label>
                </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>I need to check how many likes I got.</label>
                </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>I don't know what else to do in my free time.</label>
                </div>

                <div style={formItemStyle}>
                    <label>Add anything we missed here:</label>
                    <input type="text"></input>
                </div>

            </form>
            <Link style={linkStyle} to='/challenge-your-thoughts'>Next</Link>

        </div>
    );
}

export default AutomaticThoughts;