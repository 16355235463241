import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import logo from '../img/logo.jpg';


function Trigger() {
    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        marginTop: "20px",
        fontSize: "1.25rem"
    }

    const formStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
    }


    const logoStyle = {
        height: "100px",
        width: "100px",
      }

    const boldedStyle = {
        color: "mediumpurple", 
        fontWeight: "bold"

    }

    const linkStyle = {
        fontSize: "1.25rem",
        paddingTop: "10px",
    }
      
      

    return(
        <div style={mainContainerStyle}>
            <p>What is your trigger?</p>
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            <p> Triggers can be internal or external.
            <br/><br/><span style={boldedStyle}>Internal triggers</span> include boredom or anxiety.
            <br/><br/>An <span style={boldedStyle}>external trigger</span> might be a notification,
            <br/>or your friend saying something.
            <br/>
            <br/></p>

            <form style={formStyle}>
                <label >My trigger</label>
                <input type="text" placeholder="Feeling bored"></input>
            </form>

            <Link style={linkStyle} to='/automatic-thoughts-and-beliefs'>Next</Link>
            <Outlet/>

        </div>
    );
}

export default Trigger;