// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMgtN6MdOhX1p6_ZSo9iMFLYMOh2bBX0c",
  authDomain: "cbtfortechaddiction.firebaseapp.com",
  projectId: "cbtfortechaddiction",
  storageBucket: "cbtfortechaddiction.appspot.com",
  messagingSenderId: "187460402119",
  appId: "1:187460402119:web:dd405d0c407e0e1eeff7e2",
  measurementId: "G-1BRCQFQB7R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);


const auth = getAuth();

signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      console.log(uid);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
  

/*

 Collection A (Users)

    User 1's document: I think array of object...
      1st entry 
          Want
          Trigger
          Checkboxes
          Reframed Thought
      2nd entry
          Want
          Trigger
          Checkboxes
          Reframed Thought
      3rd entry
          ...
      
    User 2's document:
      1st entry 
          Want
          Trigger
          Checkboxes
          Reframed Thought
      2nd entry
          ....
          
    User 3's document
    .
    ..
    User 4's document

*/