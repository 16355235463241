import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import logo from '../img/logo.jpg';


function ChallengeYourThoughts() {

    const mainContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        marginTop: "20px",
        fontSize: "1.25rem"
    }

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    }

    const formItemStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "5px"
    }


    const logoStyle = {
        height: "100px",
        width: "100px",
      }

    const linkStyle = {
        fontSize: "1.25rem",
        paddingTop: "10px",
    }



    return (
        <div style={mainContainerStyle}>
            
            <img style={logoStyle} src={logo} alt="Logo showing a hand holding a phone"/>

            <p> How can you challenge your thoughts?</p>

            <form style={formStyle}>

            <div style={formItemStyle}>
                <input type="checkbox"/>
                <label>I say that it will only take five minutes, but it never does.
                </label>
            </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>Will I really feel better after scrolling? 
                    </label>
                </div>

                <div style={formItemStyle}>
                    <input type="checkbox"/>
                    <label>Do I really have to check this right now?
                    </label>
                </div>

                <div style={formItemStyle}>
                    <label>Add your own here:</label>
                    <input type="text"></input>
                </div>
            </form>
            <Link style={linkStyle} to='/reframed-thought'>Next</Link>

        </div>
    );
}

export default ChallengeYourThoughts;