import './App.css';
import React from 'react';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage.js';
import WantToDo from './pages/WantToDo.js';
import ErrorPage from './pages/ErrorPage.js';
import Trigger from './pages/Trigger.js';
import AutomaticThoughts from './pages/AutomaticThoughts.js';
import ChallengeYourThoughts from './pages/ChallengeYourThoughts.js';
import ReframedThought from './pages/ReframedThought.js';
import Congrats from './pages/Congrats.js';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage/>}></Route>
        <Route path="what-do-you-want-to-do" element={<WantToDo/>}></Route>
        <Route path="trigger" element={<Trigger/>}></Route>
        <Route path="automatic-thoughts-and-beliefs" element={<AutomaticThoughts/>}></Route>
        <Route path="challenge-your-thoughts" element={<ChallengeYourThoughts/>}></Route>
        <Route path="reframed-thought" element={<ReframedThought/>}></Route>
        <Route path="congrats" element={<Congrats/>}></Route>
        <Route path="*" element={<ErrorPage/>}/>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
